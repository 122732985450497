import React, { useContext } from "react";
import { 
    Box, Chip 
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import styles from '../../newTicketsTabCore/top_bar/TopBar.module.css'
import SortIcon from '@mui/icons-material/Sort';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { UserContext } from "../../../../../contexts/UserContext";

export function ReportedIssueTopChip({actions, setActions}) {

    const chips = [];
    const {teamDetails, loginUserEmail, allServers} = useContext(UserContext);
    const userServers = teamDetails.current[loginUserEmail.current]?.Servers?.split(",");

    // search
    if(actions.search_text !== undefined) {
        chips.push({
            "label": actions.search_text,
            "type": "search",
            "onClick": () => {},
            "onDelete": () => {
                setActions({
                    ...actions,
                    search_text: undefined,
                    meta: {
                        ...actions.meta
                    }
                });
            },
            "deletable": true
        });
    }
    
    // filter
    if(actions?.filters?.servers?.length !== 0 && actions?.filters?.servers?.length !== undefined) {
        actions.filters.servers.map(serv => {
            chips.push({
                "label": serv,
                "type": "filter",
                "onClick": () => {
                    setActions({
                        ...actions,
                        filters: {
                            ...actions.filters,
                            servers: [serv]
                        }
                    })
                },
                "onDelete": () => {
                    var newServers = actions?.filters?.servers?.filter(servr => servr !== serv);
                    if (newServers.length === 0) {
                        newServers = userServers.filter((serv) => serv !== "Test");
                    }
                    setActions({
                        ...actions,
                        filters: {
                            ...actions?.filters,
                            servers: newServers
                        }
                    })
                },
                "deletable": true
            });
        })
    }

    if(actions?.filters?.module?.length !== 0 && actions?.filters?.module?.length !== undefined) {
        actions.filters.module.map(mod => {
            chips.push({
                "label": mod,
                "type": "filter",
                "onClick": () => {
                    setActions({
                        ...actions,
                        filters: {
                            ...actions.filters,
                            module: [mod],
                            type: []
                        }
                    })
                },
                "onDelete": () => {
                    var newModules = actions?.filters?.module?.filter(md => md !== mod);
                    setActions({
                        ...actions,
                        filters: {
                            ...actions?.filters,
                            module: newModules
                        }
                    })
                },
                "deletable": true
            });
        })
    }

    if(actions?.filters?.type?.length !== 0 && actions?.filters?.type?.length !== undefined) {
        actions.filters.type.map(typ => {
            chips.push({
                "label": typ,
                "type": "filter",
                "onClick": () => {
                    setActions({
                        ...actions,
                        filters: {
                            ...actions.filters,
                            type: [typ],
                            module: []
                        }
                    })
                },
                "onDelete": () => {
                    var newTypes = actions?.filters?.type?.filter(tp => tp !== typ);
                    setActions({
                        ...actions,
                        filters: {
                            ...actions?.filters,
                            type: newTypes
                        }
                    })
                },
                "deletable": true
            });
        })
    }

    return (
        <Box className={styles.top_bar_chips + " filter-sort-overflow-container"} >
            {
                chips.map((chip, index) => {
                    return (
                        <FilterChip key={index} type={chip.type} label={chip.label} onClick={chip.onClick} onDelete={chip.onDelete} deletable={chip.deletable} />
                    )
                })
            }
            <Box sx={{minWidth: "80px"}}></Box>
        </Box>
    );
}

function FilterChip({type, label, onClick, onDelete, deletable}) {
    return (
        <Chip
            icon={
                type === "search" ?
                    <SearchIcon />
                    :
                type === "sort" ?
                    <SortIcon />
                    :
                type === "filter" ?
                    <FilterAltIcon />
                    :
                type === "completed" ? 
                    <CheckCircleIcon />
                    :
                type === "ongoing" ?
                    <PendingRoundedIcon />
                    :
                type === "rejected" ?
                    <RemoveCircleIcon />
                    :
                    <RemoveCircleIcon />
            }
            label={label}
            onClick={onClick}
            onDelete={deletable ? onDelete : undefined}
            variant="contained"
            size="small"
        />
    );
}