import React, { useState, useContext, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material';
import { UserContext } from '../../../contexts/UserContext';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AllOutIcon from '@mui/icons-material/AllOut';
import BlockIcon from '@mui/icons-material/Block';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import NotesIcon from '@mui/icons-material/Notes';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DownloadIcon from '@mui/icons-material/Download';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LinkIcon from "@mui/icons-material/Link";
import {  IconButton } from "@mui/material";

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import RetainIcon from '../../../icons/RetainIcon.png';
import ConsiderIcon from '../../../icons/ConsiderRoadmap.png';
import AcceptIcon from '../../../icons/AcceptIcon.png';
import ClarificationIcon from '../../../icons/NeedClarificationIcon.png';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import download_icon from '../../../icons/download_icon.png';
import { Fade } from '@mui/material';

import {
    Box,
    Button,
    ButtonGroup,
    Skeleton,
    Collapse,
    TextField,
    Paper,
    Chip,
} from "@mui/material";

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';

import CancelIcon from '@mui/icons-material/Cancel';

import PendingActionsIcon from '@mui/icons-material/PendingActions';

import { keyframes } from '@mui/system';

import { group_by_order, find_row_span } from '../../../util/GroupingFunctions.js';
import { queryDB } from "../../../util/QueryDB";
import { EmployeeCards } from '../../../util/Loading.js';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs'; // Import dayjs library
import {
    internal_component_get_query_1, internal_component_get_query_2, internal_component_get_query_3, internal_component_get_query_4,
    internal_component_get_query_5, internal_component_get_query_6, internal_component_get_query_7, internal_component_get_query_8,
    internal_component_get_query_9, internal_component_get_query_10
} from '../../../catalystDB/queryGenerator.js';

export default function InternalComponent({ selectedOption, setSelectedOption, searchText, setSearchText }) {
    const [loaded, setLoaded] = useState(undefined);
    const [rows, setRows] = useState([]);
    const [updatedValue, setUpdatedValue] = useState(false);
    const [toggleOpenOrClosed, setToggleOpenOrClosed] = useState(true);
    const [sortBy, setSortBy] = useState("Default");
    const { userDetails, teamDetails, loginUserEmail } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const userserverArr = teamDetails.current[user_email].Servers.split(",");
    const [filterBy, setFilterBy] = useState(userserverArr);
    const { loginUserDept, GlobalServer } = useContext(UserContext);
    var SelectedGlobalServer = (GlobalServer === "unknown" || GlobalServer === undefined) ? "No Server" : GlobalServer;
    const userServers = teamDetails.current[user_email].Servers;
    const loginUserRole = teamDetails.current[loginUserEmail.current].Role;
    const userModule = teamDetails.current[loginUserEmail.current].Module;


    var rrows = [];

    async function QueryData() {
        try {
            if (toggleOpenOrClosed === true) {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(internal_component_get_query_1(loginUserDept, userServers, userModule));
                    return result;
                }
                else {
                    const result = await queryDB(internal_component_get_query_2(loginUserDept, SelectedGlobalServer));
                    return result;
                }
            }
            else if (toggleOpenOrClosed === null) {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(internal_component_get_query_5(loginUserDept, userServers, userModule));
                    return result;
                }
                else {
                    const result = await queryDB(internal_component_get_query_6(loginUserDept, SelectedGlobalServer));
                    return result;
                }
            }
            else if (toggleOpenOrClosed === undefined) {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(internal_component_get_query_7(loginUserDept, userServers, userModule));
                    return result;
                }
                else {
                    const result = await queryDB(internal_component_get_query_8(loginUserDept, SelectedGlobalServer));
                    return result;
                }
            }
            else if (toggleOpenOrClosed === "Design") {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(internal_component_get_query_9(loginUserDept, userServers, userModule));
                    return result;
                }
                else {
                    const result = await queryDB(internal_component_get_query_10(loginUserDept, SelectedGlobalServer));
                    return result;
                }
            }
            else {
                if (SelectedGlobalServer === "No Server") {
                    const result = await queryDB(internal_component_get_query_3(loginUserDept, userServers, userModule));
                    return result;
                }
                else {
                    const result = await queryDB(internal_component_get_query_4(loginUserDept, SelectedGlobalServer));
                    return result;
                }
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    useEffect(() => {
        setLoaded(undefined);
    }, [GlobalServer, toggleOpenOrClosed]);

    useEffect(() => {
        QueryData()
            .then((queryResult) => {
                //console.log('Query result - Low Priority:', queryResult);
                if (queryResult.length > 0) {
                    for (var item of queryResult) {
                        var rrow = [];
                        rrow.push(item.ExternalFeatureReport.Feature);
                        rrow.push(item.ExternalFeatureReport.RequestedBy);
                        rrow.push(item.ExternalFeatureReport.Module);
                        rrow.push(item.ExternalFeatureReport.ROWID);
                        rrow.push(item.ExternalFeatureReport.FeaturePriority);
                        rrow.push(item.ExternalFeatureReport.CreatorRowid);//5
                        rrow.push(item.ExternalFeatureReport.MeetingScheduled);//6
                        rrow.push(item.ExternalFeatureReport.CREATEDTIME);
                        rrow.push(item.ExternalFeatureReport.Over_All_ETA);//no:8
                        rrow.push(item.ExternalFeatureReport.DocumentAgreed);//No:9
                        rrow.push(item.ExternalFeatureReport.FilestoreID); //to fetch filestore id for document
                        rrow.push(item.ExternalFeatureReport.CompletionDate);
                        rrow.push(item.ExternalFeatureReport.TasklistStatus);
                        rrow.push([undefined, "", " ", null].includes(item.ExternalFeatureReport.ReleasePlannerUrl) ? "-" : item.ExternalFeatureReport.ReleasePlannerUrl);
                        rrow.push([undefined, "", " ", null].includes(item.ExternalFeatureReport.Description) ? "-" : item.ExternalFeatureReport.Description);
                        rrow.push([undefined, "", " ", null].includes(item.ExternalFeatureReport.commentsData) ? "-" : item.ExternalFeatureReport.commentsData); // 15
                        rrow.push([undefined, "", " ", null].includes(item.ExternalFeatureReport.DesignFolderID) ? "-" : item.ExternalFeatureReport.DesignFolderID); // 16
                        rrows.push(rrow);
                    }
                    setRows(rrows);
                    setLoaded(true);
                } else {
                    setLoaded(false);

                }
            })
            .catch((error) => {
                console.error('Error querying Roadmap data:', error);
                setLoaded(null);

            });
    }, [selectedOption, GlobalServer, toggleOpenOrClosed]);

    //console.log(rows);

    if (loaded === undefined) {
        return (
            <>
                <InternalComponentTopBar searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed} />
                <br></br>
                <Skeleton variant="rectangular" width={"100%"} height={115} />
                <br></br>
                <Skeleton variant="rectangular" width={"100%"} sx={{ flex: 1 }} />
            </>
        );
    } else if (loaded === null) {
        return (
            "something went wrong..."
        );
    } else if (loaded === false) {
        return (
            <>
                <InternalComponentTopBar searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed} />
                <h2 style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    No RoadMap Found :(
                </h2>
            </>
        );
    } else {

        return (
            <>
                <InternalComponentTopBar searchText={searchText} setSearchText={setSearchText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} sortBy={sortBy} setSortBy={setSortBy} filterBy={filterBy} setFilterBy={setFilterBy} toggleOpenOrClosed={toggleOpenOrClosed} setToggleOpenOrClosed={setToggleOpenOrClosed} />
                <InternalComponentTable selectedOption={selectedOption} setSelectedOption={setSelectedOption} rows={rows} searchText={searchText} setSearchText={setSearchText} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue} filterBy={filterBy} setFilterBy={setFilterBy} setRows={setRows} toggleOpenOrClosed={toggleOpenOrClosed} loginUserRole={loginUserRole} />
            </>
        );
    }
}


function InternalComponentTopBar({ searchText, setSearchText, selectedOption, setSelectedOption, sortBy, setSortBy, filterBy, setFilterBy, toggleOpenOrClosed, setToggleOpenOrClosed }) {
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [serverAnchor, setServerAnchor] = useState(null);
    const [serversList, setServersList] = useState([]);
    const { userDetails, teamDetails } = useContext(UserContext);
    const user_email = userDetails.current.auth.email_id;
    const user_servers = teamDetails.current[user_email].Servers;
    const [sortAnchor, setSortAnchor] = useState(null);
    const [filterAnchor, setFilterAnchor] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedLabel, setSelectedLabel] = React.useState('Ongoing'); // Initial label for the button
    const [openFeatureRequestForm, setOpenFeatureRequestForm] = useState(false);
    const [iframeLoadedFeatureRequest, setIframeLoadedFeatureRequest] = useState(false);

    const sortedServersList = [...serversList].sort((a, b) => a.localeCompare(b));
    var serversMenuList = sortedServersList.map((item, index) => {
        return <MenuItem key={index} onClick={() => handleServerItemClick(item)}>{item}</MenuItem>
    });

    useEffect(() => {
        async function fetchData() {
            try {
                await setServerDropDown(setServersList, userDetails, teamDetails);
            }
            catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, []);

    async function setServerDropDown(setServersList, userDetails, teamDetails) {
        return new Promise((resolve, reject) => {
            const userEmail = userDetails.current.auth.email_id;
            const userRole = teamDetails.current[userEmail].Role;

            if (userRole === "CEO") {
                setServersList(teamDetails.current["mariaraj@zohocorp.com"].Servers.split(","));
            }
            else {
                setServersList(teamDetails.current[userEmail].Servers.split(","));
            }
            return resolve();
        });
    }

    function handleSortButtonClick(event) {
        setSortAnchor(event.currentTarget);
    }

    function handleSortButtonClose() {
        setSortAnchor(null);
    }
    function handleServerButtonClose() {
        setServerAnchor(null);
        setFilterAnchor(null);
    }
    function handleSortItemClick(item) {
        setSortAnchor(null);
        setSortBy(item);
    }
    const minimize = false;

    const handleOpenPopup = () => {
        setOpenPopup(true);
    };

    const handleFeatureRequestForm = () => {
        setOpenFeatureRequestForm(true);
    };

    const handleCloseFeatureRequestForm = () => {
        setOpenFeatureRequestForm(false);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleIframeLoad = () => {
        setIframeLoaded(true);
    };

    const handleIframeLoadFeatureRequest = () => {
        setIframeLoadedFeatureRequest(true);
    };

    function handleSearchTextChange(event) {
        setSearchText(event.target.value);
    }

    function handleSearchButtonClick() {
        setSearchText("");
        setOpen(!open);
    }

    const handleSelectOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelectClose = () => {
        setAnchorEl(null);
    };

    const handleOpenClosedOrOngoingTab = (value) => {
        setSelectedLabel(value);
        if (value == 'Completed') {
            setToggleOpenOrClosed(false);
        }
        else if (value == 'Pending') {
            setToggleOpenOrClosed(null);
        }
        else if (value == 'Ongoing') {
            setToggleOpenOrClosed(true);
        }
        else if (value == 'Design') {
            setToggleOpenOrClosed('Design');
        }
        else if (value == 'All') {
            setToggleOpenOrClosed(undefined);
        }
        handleSelectClose();
    }

    function handleMeetingButtonClick() {
        console.log("meeting clicked");
    }
    function handleFilterButtonClose() {
        setFilterAnchor(null);
    }
    function handleFilterButtonClick(event) {
        setFilterAnchor(event.currentTarget);
    }

    async function handleFilterItemClick(event, item) {
        if (item === "Server") {
            setServerAnchor(event.currentTarget);
        }
        else if (item === "Default") {
            try {
                setFilterBy(["Default"]);
            }
            catch (error) {
                console.log(error);
            }
        }

    }

    async function handleServerItemClick(item) {
        var tempFilterBy = [...filterBy];
        var index = tempFilterBy.indexOf("Default");
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        index = tempFilterBy.indexOf(item);
        if (index > -1) {
            tempFilterBy.splice(index, 1);
        }
        setFilterBy([item, ...tempFilterBy]);
        // setServerSelected(item);
        // setServerAnchor(null);
        // setFilterAnchor(null);
    }
    const grow = keyframes`
	0% {
		scale: 1;
	}
	20% {
		scale: 1.05;
		rotate: 0deg;
	}
	35% {
		rotate: -5deg;
	}
	45% {
		rotate: 5deg;
	}
	65% {
		rotate: -5deg;
	}
	80% {
		scale: 1.05;
		rotate: 0deg;
	}
	100% {
		scale: 1;
	}
	`;

    function handleChipClick(event, item) {
        if (event.detail === 2) {
            setFilterBy([item]);
        }
    }

    return (
        <>
            <div className="external-top-bar">
                <div className="external-top-bar-left" style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                }}>
                    <Box style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                        width: "calc(50vw)",
                        overflowX: "scroll"
                    }}
                        className="filter-sort-overflow-container"
                    >
                        <ButtonGroup>
                        <Button variant="contained" size="small" onClick={handleFeatureRequestForm}
                            sx={{
                                borderRadius: "100px",
                                padding: 0.9,
                                paddingRight: 1,
                                paddingLeft: 1,
                                marginLeft: "5px"
                            }}>
                            <AddIcon sx={{ fontSize: "1.2rem" }} />
                        </Button>
                        <Tooltip title="Meeting"><Button sx={{ borderRadius: "100px"}} variant="contained" size="small" onClick={handleOpenPopup} ><PendingActionsIcon /></Button></Tooltip>
                        </ButtonGroup>
                        {
                            searchText !== "" ?
                                <Chip
                                    key={"search-chip"}
                                    label={
                                        <Box style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <Box style={{

                                                color: "#0c2c70",
                                                borderRight: "1px solid #103fa3",
                                                paddingRight: "6px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}>
                                                <SearchIcon />
                                            </Box>
                                            <Box style={{
                                                paddingLeft: "6px",
                                                color: "ghostwhite"
                                                // width: "60px",
                                                // overflow: "hidden",
                                                // textOverflow: "ellipsis",
                                                // textAlign: "center"
                                            }}>
                                                {/* <Tooltip title={searchText} > */}
                                                {searchText}
                                                {/* </Tooltip> */}
                                            </Box>
                                        </Box>
                                    }
                                    sx={{
                                        bgcolor: "#226DB4 !important",
                                        border: "1px solid #0c2c70"
                                    }}
                                    onDelete={() => {
                                        setSearchText("");
                                    }}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                            sx={{
                                                color: "#0c2c70 !important"
                                            }}
                                        />
                                    }
                                />
                                :
                                <></>
                        }
                        <div style={{
                        }} className="unselectable">
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        </div>
                    </Box>
                </div>

                <div className="milestone-top-bar-right-group">
                    <ButtonGroup variant="contained">
                        <Collapse in={open} timeout="auto" orientation="horizontal" unmountOnExit>
                            <TextField variant="outlined" placeholder="search" onChange={handleSearchTextChange} value={searchText} autoFocus />
                        </Collapse>
                        <Tooltip title="search"><Button variant="contained" size="small" onClick={handleSearchButtonClick}><SearchIcon /></Button></Tooltip>
                        <Tooltip title="Options">
                            <Button
                                id="customized-button"
                                aria-controls={Boolean(anchorEl) ? 'customized-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                variant="contained"
                                disableElevation
                                onClick={handleSelectOpen}
                                endIcon={<KeyboardArrowDownIcon />}
                                sx={{ fontSize: "11px", width: "110px" }}
                            >
                                {selectedLabel} {/* Display the selected label */}
                            </Button>
                        </Tooltip>
                        <Menu
                            id="customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleSelectClose}
                        >
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Pending')}
                                disableRipple
                            >
                                <AllOutIcon fontSize="small" sx={{ fontSize: "17px" }} />
                                &nbsp;&nbsp;Validation
                            </MenuItem>
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Design')}
                                disableRipple
                            >
                                <DesignServicesIcon fontSize="small" sx={{ fontSize: "17px" }} />
                                &nbsp;&nbsp;Design 
                            </MenuItem>
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Ongoing')}
                                disableRipple
                            >
                                <AssignmentTurnedInIcon fontSize="small" sx={{ fontSize: "15px" }} />
                                &nbsp;&nbsp;Ongoing
                            </MenuItem>
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('Completed')}
                                disableRipple
                            >
                                <BlockIcon fontSize="small" sx={{ fontSize: "15px" }} />
                                &nbsp;&nbsp;Completed
                            </MenuItem>
                            <MenuItem
                                sx={{ width: "124px", justifyContent: "flex-start", fontSize: "12px", display: 'flex', alignItems: 'center' }}
                                onClick={() => handleOpenClosedOrOngoingTab('All')}
                                disableRipple
                            >
                                <GroupWorkIcon fontSize="small" sx={{ fontSize: "15px" }} />
                                &nbsp;&nbsp;All
                            </MenuItem>
                        </Menu>
                        {/* <Tooltip title="filter by"><Button variant="contained" size="small" onClick={handleFilterButtonClick}><FilterAltOutlinedIcon /><ArrowDropDownIcon /></Button></Tooltip> */}
                        {/* <Tooltip title="sort by"><Button variant="contained" size="small" onClick={handleSortButtonClick}><SortIcon  /><ArrowDropDownIcon  /></Button></Tooltip> */}
                    </ButtonGroup>
                    {/* <Select
                                value={toggleValue}
                                onChange={handleOpenClosedOrOngoingTab}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                sx={{ m: 1, minWidth: 120,width:"20px"}}
                                >
                                    <MenuItem value={10}>Ongoing  <AllOutIcon fontSize='small' />  </MenuItem>
                                    <MenuItem value={20}>Completed</MenuItem>
                                    <MenuItem value={30}>Set ETA</MenuItem>
                                </Select> */}
                    <Menu
                        anchorEl={sortAnchor}
                        open={sortAnchor !== null}
                        onClose={handleSortButtonClose}
                    >
                        <MenuItem onClick={() => handleSortItemClick("Default")}>Default</MenuItem>
                        <MenuItem onClick={() => handleSortItemClick("Server")}>Server</MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={filterAnchor}
                        open={filterAnchor !== null}
                        onClose={handleFilterButtonClose}
                    >
                        <MenuItem onClick={(event) => handleFilterItemClick(event, "Default")} style={{ justifyContent: "center" }}>Default</MenuItem>
                        <MenuItem onClick={(event) => handleFilterItemClick(event, "Server")}><ArrowLeftIcon />Server</MenuItem>
                    </Menu>
                    <Menu
                        anchorEl={serverAnchor}
                        open={serverAnchor !== null}
                        onClose={handleServerButtonClose}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        {serversMenuList}
                    </Menu>
                    <Dialog open={openPopup} onClose={handleClosePopup} sx={{
                        display: minimize ? "none" : "block"
                    }} fullScreen>
                        <DialogActions>
                            <Button onClick={handleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
                        </DialogActions>
                        <DialogContent>
                            {!iframeLoaded && (
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                        borderRadius: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                        animation="wave"
                                    />
                                </div>
                            )}
                            <iframe
                                src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/MeetingSchedule?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true"
                                width="100%"
                                height="100%"
                                title="External Content"
                                onLoad={handleIframeLoad}
                                style={{ display: iframeLoaded ? 'block' : 'none' }}
                            />
                        </DialogContent>
                    </Dialog>

                    <Dialog open={openFeatureRequestForm} onClose={handleCloseFeatureRequestForm} sx={{
                        display: minimize ? "none" : "block"
                    }} fullScreen>
                        <DialogActions>
                            <Button onClick={handleCloseFeatureRequestForm} variant="text" fontSize="large"><CloseIcon /></Button>
                        </DialogActions>
                        <DialogContent>
                            {!iframeLoadedFeatureRequest && (
                                <div
                                    style={{
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                        borderRadius: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                        animation="wave"
                                    />
                                </div>
                            )}
                            <iframe
                                src="https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/External_Feature_Request?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true&Type_of_Feature=Internal"
                                width="100%"
                                height="100%"
                                title="External Content"
                                onLoad={handleIframeLoadFeatureRequest}
                                style={{ display: iframeLoadedFeatureRequest ? 'block' : 'none' }}
                            />
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </>
    );
}

function InternalComponentTable({ selectedOption, setSelectedOption, rows, searchText, setSearchText, updatedValue, setUpdatedValue, filterBy, setFilterBy, setRows, toggleOpenOrClosed, loginUserRole }) {

    rows = rows.filter(row_ => {
        for (const i in row_) {
            const item = row_[i];
            if (item === null || item === undefined) {
                continue;
            }
            const aString = item.toString().toLowerCase().replaceAll(" ", "");
            const bString = searchText.toLowerCase().replaceAll(" ", "");
            if (aString.indexOf(bString) !== -1) {
                return true;
            }
        }
        return false;
    });

    // rows = rows.filter(row_ => {
    //     const server_name = row_[2];
    //     if(filterBy.includes("Default")) {
    //         return true;
    //     }
    //     if(filterBy.includes(server_name)) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // });

    function CompareFunction(a, b) {

        const priorityMap = {
            high: 1,
            medium: 2,
            low: 3,
            none: 4
        };

        const priorityA = a[2] ? a[2].toLowerCase() : ''; // Ensure it's a valid string or empty string
        const priorityB = b[2] ? b[2].toLowerCase() : '';

        const priorityValueA = priorityMap[priorityA] || Number.MAX_VALUE;
        const priorityValueB = priorityMap[priorityB] || Number.MAX_VALUE;


        return priorityValueA - priorityValueB;
    }

    rows = rows.sort(CompareFunction);

    var filteredData = rows;
    let rowSpanInfo = [];
    var dataValue = [];

    if (rows.length > 0) {
        filteredData = group_by_order(rows, [2], 0);
        //console.log(filteredData);

        rowSpanInfo = find_row_span(filteredData, [2]);
        // console.log(rowSpanInfo);
    }

    return (
        <>
            <div className="external-table">
                <TableContainer component={Paper} className="external-table-container">
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow className="external-table-head">
                                <TableCell align="left" className="external-table-grouper-header" />
                                <TableCell align="left" style={{ width: '1vw' }}>S.No.</TableCell>
                                <TableCell align="center" style={{ width: '20vw' }}>Feature</TableCell>
                                <TableCell align="center" style={{ width: '20vw' }}>Priority</TableCell>
                                <TableCell align="center" style={{ width: '10vw' }}>Created By</TableCell>
                                <TableCell align="center" style={{ width: '1vw' }}></TableCell>
                                {
                                    toggleOpenOrClosed === true ?
                                        <TableCell align="center" style={{ width: '1vw' }}>Due on</TableCell>
                                        :
                                        toggleOpenOrClosed === false ?
                                            <TableCell align="center" style={{ width: '1vw' }}>Completion Date</TableCell>
                                            :
                                            toggleOpenOrClosed === null ?
                                                <TableCell align="center" style={{ width: '1vw' }}>Set ETA Date</TableCell>
                                                :
                                                toggleOpenOrClosed === "Design" ?
                                                    <TableCell align="center" style={{ width: '1vw' }}>Design</TableCell>
                                                    :
                                                    <TableCell align="center" style={{ width: '1vw' }}>ETA</TableCell>
                                }
                                {/* {toggleOpenOrClosed == true ? ( 
                                    <TableCell align="center" style={{ width: '1vw' }}>Due on</TableCell> 
                                ):(
                                    <TableCell align="center" style={{ width: '1vw' }}>Completion Date</TableCell> 
                                )}                             */}
                                <TableCell align="right" className="external-table-grouper-header" />
                            </TableRow>
                        </TableHead>
                        <TableBody id="list-view">
                            {
                                Array.isArray(filteredData) ?
                                    filteredData.map((row, index) => {
                                        return <InternalComponentRow key={index} row={row} index={index} rowSpanInfo={rowSpanInfo} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue} setRows={setRows} filteredData={filteredData} toggleOpenOrClosed={toggleOpenOrClosed} loginUserRole={loginUserRole} />;
                                    })
                                    :
                                    null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

function InternalComponentRow({ row, index, rowSpanInfo, updatedValue, setUpdatedValue, setRows, filteredData, toggleOpenOrClosed, loginUserRole }) {

    // console.log(filteredData);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const now = new Date();
    const [value, setValue] = useState();
    const [showButtons, setShowButtons] = useState(false);
    const [disagreeDialogOpen, setDisagreeDialogOpen] = useState(false);
    const [disagreeDescription, setDisagreeDescription] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [moreVisibile, setMoreVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isDocumentUploaded, setIsDocumentUploaded] = useState(row[16] === "-" ? false : true);
    const documentUploadRef = useRef(null);

    // Handle document upload
    const handleDocumentUpload = (file) => {
        if (file) {
            console.log("Uploading file: ", file);

            var filestore = window.catalyst.file;
            var folder = filestore.folderId(5781000012600529); //Specify the Folder ID
            var uploadPromise = folder.uploadFile(file).start(); //Pass the file object and upload the file
            uploadPromise
                    .then((response) => {
                        console.log(response.content.id);
                        var detailsSet = [
                            { "DesignFolderID": response.content.id, "ROWID": row[3] },
                        ];
            
                        var datastore = window.catalyst.table;
                        var table = datastore.tableId('5781000012249849');
                        var updatePromise = table.updateRow(detailsSet);
                        updatePromise
                            .then((response) => {
                                console.log(response.content);
                                var detailsSet = [
                                    { "TasklistStatus": null, "ROWID": row[3] },
                                ];
                        
                                var datastore = window.catalyst.table;
                                var table = datastore.tableId('5781000012249849');
                                var updatePromise = table.updateRow(detailsSet);
                                updatePromise
                                    .then((response) => {
                                        // setRows((prevRows) => prevRows.filter((currentRow) => currentRow[3] !== row[3]));
                                        //console.log(response.content);
                                    })
                                    .catch((err) => {
                                        //console.log(err);
                                    });
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            // Simulate file upload or handle it via an API call
            setTimeout(() => {
                setIsDocumentUploaded(true);
                console.log("Document uploaded successfully");
            }, 1000); // Simulated upload delay
        }
    };

    

    //console.log(user_email);

    // async function ModuleData() {
    //     try {
    //         const result = await queryDB(`SELECT value FROM Util Where conf = 'Module_ZVP'`);
    //         return result;
    //     } catch (error) {
    //         //console.log(error);
    //         throw error;
    //     }
    // }

    // var rrows = [];
    // useEffect(() => {
    //     ModuleData()
    //         .then((queryResult) => {
    //             //console.log('Query result - Low Priority:', queryResult);
    //             if (queryResult.length > 0) {
    //                 for (var item of queryResult) {
    //                     var rrow = [];

    //                     rrow.push(item.Util.value);
    //                     rrows.push(rrow);


    //                 }
    //                 setValue(rrows);
    //                 // setLoaded(true);
    //             } else {
    //                 // setLoaded(false);

    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error querying Roadmap data:', error);
    //             // setLoaded(null);

    //         });
    // }, []);


    const handleConfirmDialogOpen = () => {
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleAgreeButton = () => {
        if (loginUserRole != "Developer") {
            setShowButtons(true);
            //setRevisitDisabled(true);
            // var detailsSet = [
            //     {"DocumentAgreed":true,"MeetingScheduled":false, "ROWID": row[3]},
            //  ];
            var detailsSet = [
                { "DocumentAgreed": true, "ROWID": row[3] },
            ];

            var datastore = window.catalyst.table;
            var table = datastore.tableId('5781000012249849');
            var updatePromise = table.updateRow(detailsSet);
            updatePromise
                .then((response) => {
                    //console.log(response.content);
                    // setTimeout(() => {
                    //     console.log("One Hour later");
                    // }, 3 * 60 * 1000);
                })
                .catch((err) => {
                    //console.log(err);
                });
        }
        else {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const handleDisagreeButton = () => {
        if (loginUserRole != "Developer") {
            setDisagreeDialogOpen(true);
        }
        else {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const handleDisagreeDialogClose = () => {
        setDisagreeDialogOpen(false);
    };

    const handleDisagreeSubmit = () => {
        console.log('Disagree Description:', disagreeDescription);
        setDisagreeDescription('');
        setDisagreeDialogOpen(false);
        const url = '/server/sample?table=ExternalQueryDescription&rowid=' + row[7] + '&Description=' + disagreeDescription;
        const headers = {
            'Content-Type': 'application/json',
        };
        fetch(url, {
            method: 'POST',
            headers: headers,

        })
            .then(response => {

                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Request failed with status: ' + response.status);
                }
            })
            .then(data => {
                const statusCode = data.StatusCode;
                if (statusCode !== 3000) {
                    // handleSnackbarOpen('Error fetching data', 'error');
                }
                else {
                    //handleSnackbarOpen('Overall ETA Provided Successfully!', 'success');
                    //setOpen(!open);
                }

            })
            .catch(error => {
                console.error(error);

            });

        var detailsSet = [
            { "NeedClarification": true, "ROWID": row[3] },
        ];

        var datastore = window.catalyst.table;
        var table = datastore.tableId('5781000012249849');
        var updatePromise = table.updateRow(detailsSet);
        updatePromise
            .then((response) => {
                //console.log(response.content);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    //console.log(value);

    var disable = false;

    if (new Date(row[5]) >= now) {
        disable = true;
    }
    const [revisitDisabled, setRevisitDisabled] = useState(disable);
    const [desiredTime] = useState(new Date(row[8]));

    // useEffect(() => {
    //     // Calculate the time difference in milliseconds
    //     const currentTime = new Date();
    //     const timeDifference = desiredTime - currentTime;
    //     //console.log(timeDifference);

    //     // Set revisitDisabled to false if the time difference is less than 10 minutes (600000 milliseconds)
    //     if (timeDifference <= 600000) {
    //         setRevisitDisabled(false);
    //         var detailsSet = [
    //             { "MeetingScheduled": true, "ROWID": row[3] },
    //         ];

    //         var datastore = window.catalyst.table;
    //         var table = datastore.tableId('5781000012249849');
    //         var updatePromise = table.updateRow(detailsSet);
    //         updatePromise
    //             .then((response) => {
    //                 //console.log(response.content);
    //             })
    //             .catch((err) => {
    //                 //console.log(err);
    //             });
    //     }
    // }, [desiredTime]);

    const handleAddDesignAndETA = () => {
        setConfirmDialogOpen(false);
        var detailsSet = [
            { "TasklistStatus": "Design", "ROWID": row[3] },
        ];

        var datastore = window.catalyst.table;
        var table = datastore.tableId('5781000012249849');
        var updatePromise = table.updateRow(detailsSet);
        updatePromise
            .then((response) => {
                setRows((prevRows) => prevRows.filter((currentRow) => currentRow[3] !== row[3]));
                //console.log(response.content);
            })
            .catch((err) => {
                //console.log(err);
            });
        // Call your function to handle "Add Design Doc and Set ETA"
        console.log("Add Design Doc and Set ETA selected");
        // Your function logic here...
    };

    const handleSetETA = () => {
        setConfirmDialogOpen(false);
        // Call the existing "handleConsiderButton" function
        handleConsiderButton();
    };

    const handleConsiderButton = () => {
        if (loginUserRole != "Developer") {
            if (!revisitDisabled) {
                setOpen(!open);
            }
        }
        else {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const handleRevisitButton = () => {
        if (loginUserRole != "Developer") {
            if (!revisitDisabled) {
                setOpenConfirmation(true);
            }
        }
        else {
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    }

    const handleViewDocument = () => {
        var filestore = window.catalyst.file;
        var folder = filestore.folderId("5781000012600529");
        var file = folder.fileId(row[10]);
        var downloadPromise = file.getDownloadLink();
        downloadPromise
            .then((response) => {
                //console.log(response.content);
                //console.log("https://zvpone-709834020.catalystserverless.com" + response.content.download_url);
                // Create a hidden anchor element
                const a = document.createElement('a');
                a.style.display = 'none';

                // Set the anchor's href to the download URL
                a.href = "https://zvpone-709834020.catalystserverless.com" + response.content.download_url;

                // Set the anchor's download attribute if you want to specify a download filename
                // a.download = "filename.txt";

                // Trigger a click on the anchor element
                document.body.appendChild(a);
                a.click();

                // Clean up by removing the anchor element
                document.body.removeChild(a);
            });
    }

    const confirmRevisit = () => {

        setRevisitDisabled(true);
        setOpenConfirmation(false);
        const now = new Date();
        const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

        var detailsSet = [
            { "RevisitTime": startOfNextMonth.toString(), "MeetingScheduled": false, ScheduledMeetingTime: "", "ROWID": row[3] },
        ];

        var datastore = window.catalyst.table;
        var table = datastore.tableId('5781000012249849');
        var updatePromise = table.updateRow(detailsSet);
        updatePromise
            .then((response) => {
                //console.log(response.content);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const cancelRevisit = () => {
        setOpenConfirmation(false);
    };

    var originalDate;
    var dueDate;
    if (toggleOpenOrClosed) {
        originalDate = row[8];
    }
    else {
        if (toggleOpenOrClosed === undefined) {
            originalDate = row[8];
        }
        else{
            originalDate = row[11];
        }
        
        dueDate = row[8];
    }
    // const originalDate = row[8];
    const dateObj = new Date(originalDate);
    const DueDateDateObj = new Date(dueDate);

    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short' }); // Get the short month name
    const year = dateObj.getFullYear();

    const DueDateDay = DueDateDateObj.getDate();
    const DueDateMonth = DueDateDateObj.toLocaleString('en-US', { month: 'short' }); // Get the short month name
    const DueDateYear = DueDateDateObj.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    const formattedDueDate = `${DueDateDay}-${DueDateMonth}-${DueDateYear}`;

    const handleLinkClick = (url) => {
        if (url && url !== "-") {
          window.open(url, "_blank", "noopener,noreferrer");
        }
      };

    return (
        <>
            <TableRow className="external-table-head" >
                {
                    rowSpanInfo[index][2] !== 0 ?
                        <TableCell className={`milestone-table-grouper-component milestone-single-row rotated-text ${row[2]}`} align="center" width={"6px"} rowSpan={2 * rowSpanInfo[index][2]} sx={{
                            fontSize: "0.8rem !important",
                            backgroundColor: row[2] === 'High' ? 'red !important' : row[2] === 'Medium' ? 'Orange !important' : row[2] === 'Low' ? 'lightgreen !important' : 'transparent'
                        }} >
                            {row[2]}
                        </TableCell>
                        :
                        null
                }
                <TableCell align="center">{index + 1}</TableCell>
                <Tooltip title={row[14]}>
                <TableCell align="center" style={{ marginRight: row[13] !== "-" ? "8px" : "0" }}>
                    {row[0]}
                    {row[13] !== "-" && (
                    <Tooltip title="Open Link">
                    <IconButton
                        onClick={() => handleLinkClick(row[13])}
                        size="small"
                        style={{
                            color: "#007bff",
                            marginLeft: "8px", // Add spacing between text and icon
                            transition: "transform 0.2s ease, color 0.2s ease",
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <LinkIcon />
                    </IconButton>
                    </Tooltip>
                )}
                </TableCell>
                </Tooltip>
                <TableCell align="center" >{row[4]}</TableCell>
                <TableCell align="center" ><EmployeeCards emails={row[1]} /></TableCell>
                <TableCell align="center">
                    {row[10] !== null && (
                        <Button variant="contained" color="action" size="small" onClick={handleViewDocument} sx={{ padding: 0 }}>
                            <Tooltip title="Click to Download Document">
                                <img src={download_icon} height={30} width={30} />
                            </Tooltip>
                        </Button>
                    )}
                </TableCell>
                <TableCell align="center" style={{ paddingRight: 0, color: "orange", width: "5vw" }}>
                    { row[12] == "Design" ? (
                         <>
                         {!isDocumentUploaded ? (
                             <>
                                 <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => documentUploadRef.current.click()}
                                    sx={{
                                        height: '33px',
                                        minWidth: '60px', // Override default button width
                                        fontSize: '0.6rem', // Adjust font size
                                        padding: '2px 6px', // Adjust padding for smaller dimensions
                                    }}
                                >
                                    Upload Document
                                </Button>

                                 <input
                                     type="file"
                                     ref={documentUploadRef}
                                     style={{ display: 'none' }}
                                     onChange={(e) => handleDocumentUpload(e.target.files[0])}
                                 />
                             </>
                         ) : (
                             <>
                                 <Button variant="contained" color="action" size="small" marginRight="8px" onClick={row[16] === "-" ? handleConfirmDialogOpen : handleSetETA} disabled={revisitDisabled}>
                                     <Tooltip title="Consider"><img src={ConsiderIcon} width={"28px"} /></Tooltip>
                                 </Button>
                             </>
                         )}
                     </>
                    ) : (
                        row[6] ? (
                        <>
                            <Box sx={{ display: 'flex', flexDirection: "row !important", gap: "10px", padding: "0px" }}>
                                {row[10] === null ? (
                                    // If row[10] is null, set showButtons to true
                                    <>
                                        <Button variant="contained" color="action" size="small" marginRight="8px" onClick={row[16] === "-" ? handleConfirmDialogOpen : handleSetETA} disabled={revisitDisabled}>
                                            <Tooltip title="Consider"><img src={ConsiderIcon} width={"28px"} /></Tooltip>
                                        </Button>
                                        <Button variant="contained" color="action" size="small" onClick={handleRevisitButton} disabled={revisitDisabled}>
                                            <Tooltip title="Retain Roadmap"><img src={RetainIcon} width={"28px"} /></Tooltip>
                                        </Button>
                                    </>
                                ) : (
                                    // If row[10] is not null, set showButtons based on the existing logic
                                    <>
                                        {showButtons || row[9] ? (
                                            <>
                                                <Button variant="contained" color="action" size="small" marginRight="8px" onClick={row[16] === "-" ? handleConfirmDialogOpen : handleSetETA} disabled={revisitDisabled}>
                                                    <Tooltip title="Consider"><img src={ConsiderIcon} width={"28px"} /></Tooltip>
                                                </Button>
                                                <Button variant="contained" color="action" size="small" onClick={handleRevisitButton} disabled={revisitDisabled}>
                                                    <Tooltip title="Retain Roadmap"><img src={RetainIcon} width={"28px"} /></Tooltip>
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button variant="contained" color="action" size="small" onClick={handleAgreeButton}>
                                                    <Tooltip title="Agree"><img src={AcceptIcon} width={"28px"} /></Tooltip>
                                                </Button>
                                                <Button variant="contained" color="action" size="small" onClick={handleDisagreeButton}>
                                                    <Tooltip title="Need clarification"><img src={ClarificationIcon} width={"28px"} /></Tooltip>
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Box>

                            <Dialog
                                open={disagreeDialogOpen}
                                onClose={handleDisagreeDialogClose}
                                maxWidth="sm"
                                fullWidth>
                                <div className="external-table">
                                    <Table stickyHeader >
                                        <TableHead>
                                            <TableRow className="external-table-head">
                                                <TableCell align="left" style={{ width: '1vw' }}>Need clarification</TableCell>
                                            </TableRow>
                                        </TableHead>
                                    </Table>
                                    <DialogContent>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Description:</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            autoFocus
                                                            margin="dense"
                                                            label="Description"
                                                            type="text"
                                                            placeholder="Provide your query for clarification."
                                                            value={disagreeDescription}
                                                            onChange={(e) => setDisagreeDescription(e.target.value)}
                                                            fullWidth
                                                            multiline
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </DialogContent>
                                </div>
                                <DialogActions>
                                    <Button onClick={handleDisagreeDialogClose} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDisagreeSubmit} color="primary">
                                        Submit
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            <Dialog open={openConfirmation} onClose={cancelRevisit}>
                                <DialogTitle>Confirm Revisit</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Are you sure, Do you want to revisit this for the next cycle?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={cancelRevisit} color="primary">
                                        Cancel
                                    </Button>
                                    <Button onClick={confirmRevisit} color="primary">
                                        Confirm
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                    ) : (
                        <>
                            {row[8] != null ?
                                row[12] == "Closed" ?
                                    <>
                                        <Box sx={{
                                            fontSize: "0.8rem",
                                            color: theme.palette.background.text
                                        }}>
                                            {formattedDate}
                                        </Box>
                                        <Box sx={{
                                            fontSize: "0.6rem",
                                            color: theme.palette.background.text
                                        }}>
                                            DueDate : {formattedDueDate}
                                        </Box>
                                    </>
                                    :
                                    formattedDate
                                :
                                null
                            }
                        </>
                    ))}
                </TableCell>
                {/* <MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} row={row} /> */}
                {
                    (row[8] === null || row[11] === null)  ? (
                        <MoreSection className='slide-animation' moreVisibile={moreVisibile} setMoreVisible={setMoreVisible} row={row} setRows={setRows}/>
                    ):null
                }
            </TableRow>
            <TableRow className="info-div-row"
                sx={{
                    backgroundColor: theme.palette.background.infoDivOuter + " !important"
                }}>
                <TableCell colSpan={10} sx={{
                    padding: "0px",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                }} >
                    {/* Content to display when the row is expanded */}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <InternalComponentHiddenRow row={row} value={value} setValue={setValue} open={open} setOpen={setOpen} updatedValue={updatedValue} setUpdatedValue={setUpdatedValue} setRows={setRows} filteredData={filteredData} />
                    </Collapse>
                </TableCell>
            </TableRow>

            <Dialog open={confirmDialogOpen} onClose={handleConfirmDialogClose}>
                <DialogTitle>Choose the next stage for this feature</DialogTitle>
                {/* <DialogContent>
                    <Box>Do you want to add a design document and set ETA, or just set ETA?</Box>
                </DialogContent> */}
                <DialogActions sx={{margin:'auto'}}>
                    <Button onClick={handleAddDesignAndETA} color="primary">
                         Design Review
                    </Button>
                    <Button onClick={handleSetETA} color="primary">
                        Set ETA
                    </Button>
                    <Button onClick={handleConfirmDialogClose} color="secondary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={showAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="info">You Don't Have Access To Consider</Alert>
            </Snackbar>
        </>
    )
}

function InternalComponentHiddenRow({ row, value, setValue, open, setOpen, updatedValue, setUpdatedValue, setRows, filteredData }) {
    {
        const theme = useTheme();
        const [selectedDate, handleDateChange] = React.useState(null);
        const { userDetails, teamDetails } = useContext(UserContext);
        const user_email = userDetails.current.auth.email_id;
        const [effortValues, setEffortValues] = useState({});
        const [submitting, setSubmitting] = useState(false);


        let modules;

        //console.log(value);
        //const value123 = value[0].join(" ");
        //console.log(value123);

        if (row[2] === "ZVP") {
            modules = ['AMSServer', 'WSServer', 'RTCP', 'Media'];
            //modules = value123;
        } else {
            modules = [row[2]];
        }
        const [selectedModule, setSelectedModule] = useState(modules[0]);

        const handleSnackbarOpen = (message, severity) => {
            setNotification({
                open: true,
                message,
                severity,
            });
        };

        const handleSnackbarClose = () => {
            setNotification({
                ...notification,
                open: false,
            });
        };

        const [notification, setNotification] = useState({
            open: false,
            message: '',
            severity: 'success', // or 'error' for example
        });



        const handleSubmit = (event) => {
            setEffortValues(0);
            setSubmitting(true);
            handleSnackbarOpen('Processing...', 'info');
            event.preventDefault();
            if (!selectedDate) {
                window.alert('Provide a date.');
                setSubmitting(false);
                return;
            }
            const formattedDate = selectedDate ? dayjs(selectedDate).format('YYYY-MM-DD') : null;
            // console.log(formattedDate);
            const effort = JSON.stringify(effortValues);
            //const modifiedEffort = effort.replace(/"/g, "'");
            const modifiedEffort = encodeURIComponent(effort);



            const url = '/server/sample?table=InternalComponent&rowid=' + row[5] + '&OverallETA=' + formattedDate + '&User=' + user_email + '&Module=' + selectedModule;
            const headers = {
                'Content-Type': 'application/json',
            };
            fetch(url, {
                method: 'POST',
                headers: headers,

            })
                .then(response => {

                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error('Request failed with status: ' + response.status);
                    }
                })
                .then(data => {
                    const statusCode = data.StatusCode;
                    // console.log(statusCode)
                    if (statusCode !== 200) {
                        handleSnackbarOpen('Error fetching data', 'error');
                        setOpen(!open);
                    }
                    else {
                        handleSnackbarOpen('Overall ETA Provided Successfully!', 'success');
                        setOpen(!open);
                        const rowIndex = filteredData.findIndex(row11 => row11.includes(row[3]));
                        // console.log(formattedDate);
                        const Newallrow = [...filteredData];
                        // console.log(filteredData);
                        Newallrow[rowIndex][8] = formattedDate;
                        Newallrow[rowIndex][11] = formattedDate;
                        Newallrow[rowIndex][6] = false;
                        // console.log(Newallrow);
                        setRows(Newallrow);
                        setSubmitting(false);
                    }

                })
                .catch(error => {
                    console.error(error);

                });

            // var detailsSet = [
            //     {"InternalOverAllETA": formattedDate, "ROWID": row[3] },
            // ];

            // var datastore = window.catalyst.table;
            // var table = datastore.tableId('5781000006856839');
            // var updatePromise = table.updateRow(detailsSet);
            // updatePromise
            //     .then((response) => {
            //         console.log(response.content);
            //     })
            //     .catch((err) => {
            //         //console.log(err);
            //     });
        };

        const originalDate = row[7];
        var months = [
            'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
            'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
        ];

        const date = new Date(originalDate);
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        const formattedDate1 = `${day}-${month}-${year}`;

        return (
            <Box style={{
                padding: "10px 0px 10px 0px",
                backgroundColor: theme.palette.background.infoDivOuter
            }}
            >
                <Paper className="info-div-paper"
                    sx={{
                        backgroundColor: theme.palette.background.infoDivInner + " !important"
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Created Time</TableCell>
                                    <TableCell style={{ padding: "10px 22px" }}>
                                        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'flex-start' }}>
                                            {formattedDate1}
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell style={{ width: '10vw', fontWeight: 'bold' }}>Requested By:</TableCell>
                                    <TableCell alignItems="center" style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "100%",
                                        justifyContent: "flex-start !important",
                                        alignItems: "center"
                                    }}>
                                        <Box sx={{
                                            width: "30%",
                                            whiteSpace: "nowrap",
                                            justifyContent: "left",
                                            flexDirection: "row",
                                            display: "flex"
                                        }} className="round-table-employee-card-item">
                                            <EmployeeCards emails={row[1]} />
                                        </Box></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ width: '10vw', fontWeight: 'bold' }}>Overall ETA:</TableCell>
                                    <TableCell >
                                        <Box display="flex" flexDirection="column" alignItems="left" sx={{
                                            width: "250px"
                                        }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    // label={selectedDate ? 'Select a Date' : 'Provide an Overall ETA'}
                                                    value={selectedDate}
                                                    onChange={(newDate) => handleDateChange(newDate)}
                                                    renderInput={(params) => <TextField {...params} required />}
                                                    disablePast
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                            <Button type="submit" variant="contained" color="primary" disabled={submitting}>
                                {submitting ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Box>
                        <Snackbar
                            open={notification.open}
                            autoHideDuration={5000}
                            onClose={handleSnackbarClose}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                onClose={handleSnackbarClose}
                                severity={notification.severity}
                            >
                                {notification.message}
                            </MuiAlert>
                        </Snackbar>
                    </form>
                    {/* </form> */}
                </Paper>
            </Box>
        );
    }
}


function MoreSection({ moreVisibile, setMoreVisible, row, setRows }) {
    const [moreAnchor, setMoreAnchor] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const { loginUserEmail } = useContext(UserContext);
    const [comments, setComments] = useState( row[15] === "-" ? [] : JSON.parse(row[15])); // State to store comments
    const [commentDialogOpen, setCommentDialogOpen] = useState(false);
    const [newComment, setNewComment] = useState("");


    function handleEditClick() {
        setOpenForm(true);
        setMoreAnchor(null);
    }

    function handleClosePopupEdit() {
        setOpenForm(false);
    }

    const handleEditIframeLoad = () => {
        setFormLoaded(true);
    };

    async function handleDeleteClick() {
        setMoreAnchor(null);
        setDeleting(true); // Start showing progress

        const detailsSet = [
            { "IsReleaseDeleted": 'Yes', "ROWID": row[3] },
        ];
        try {
            const datastore = window.catalyst.table;
            const table = datastore.tableId('5781000012249849');
            await table.updateRow(detailsSet);

            // Remove the deleted row from the rows list
            setRows((prevRows) => prevRows.filter((currentRow) => currentRow[3] !== row[3]));
        } catch (err) {
            console.error("Error deleting row:", err);
        } finally {
            setDeleting(false); // Stop showing progress
        }
    }
    const TaskName = row[0] || "Release Comments"; // Assuming row[1] contains task name

    function handleAddComment() {
        if (!newComment.trim()) return; // Prevent empty comments
    
        const commentData = {
            name: loginUserEmail.current, // Ensure name is a string
            timestamp: new Date().toLocaleString(), // Ensure timestamp is a string
            text: newComment.trim(), // Ensure text is a string
        };
        // Update the comments state
        setComments((prevComments) => [...prevComments, commentData]);
        // Clear the input field
        setNewComment("");
        const updatedCommentsData = [...comments, commentData];
        const detailsSet = [{ commentsData: JSON.stringify(updatedCommentsData), ROWID: row[3] }];
  
        const datastore = window.catalyst.table;
        const table = datastore.tableId("5781000012249849");
  
        table.updateRow(detailsSet).then(/*log*/).catch(console.error);
    }

    const handleDownloadDoc = () => {
        var filestore = window.catalyst.file;
        var fileId = row[16];
        // console.log(fileId);
        var folder = filestore.folderId("5781000012600529");
        var file = folder.fileId(fileId);
        var downloadPromise = file.getDownloadLink();
        downloadPromise
            .then((response) => {
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = "" + response.content.download_url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
    
};
    

    function handleOpenComments() {
        setCommentDialogOpen(true);
        setMoreAnchor(null);
    }

    const ReleasePlanEditUrl =
        "https://creatorapp.zoho.com/zohointranet/zvp-team/External_Feature_Request/record-edit/All_External_Feature_Requests/" + row[5] + "/";

    return (
        <TableCell align="right" style={{ width: "5px", cursor: "pointer" }}>
            <MoreVertIcon onClick={(event) => setMoreAnchor(event.currentTarget)} />
            <Menu
                anchorEl={moreAnchor}
                open={moreAnchor !== null}
                onClose={() => setMoreAnchor(null)}
            >
                {row[8] !== null && row[12] !== 'Closed' ? (
                    <>
                        <MenuItem onClick={handleOpenComments}>
                            <Box style={{ display: "flex" }}>
                                <NotesIcon />
                                &nbsp;Add/View Comments
                            </Box>
                        </MenuItem>
                    </>
                ) : (
                    <>
                        <MenuItem onClick={handleDeleteClick} disabled={deleting}>
                            <Box style={{ display: "flex" }}>
                                <DeleteIcon />
                                &nbsp;{deleting ? "Deleting..." : "Delete"}
                            </Box>
                        </MenuItem>
                        <MenuItem onClick={handleEditClick}>
                            <Box style={{ display: "flex" }}>
                                <EditLocationAltIcon />
                                &nbsp;Edit
                            </Box>
                        </MenuItem>
                        <MenuItem onClick={handleOpenComments}>
                            <Box style={{ display: "flex" }}>
                                <NotesIcon />
                                &nbsp;Add/View Comments
                            </Box>
                        </MenuItem>
                    </>
                )}
                {row[16] !== "-" && (
                    <MenuItem onClick={handleDownloadDoc}>
                        <Box style={{ display: "flex" }}>
                            <DownloadIcon />
                            &nbsp;Download Design Doc
                        </Box>
                    </MenuItem>
                )}
            </Menu>

            <Dialog open={openForm} onClose={handleClosePopupEdit} fullScreen>
                <DialogActions>
                    <Button onClick={handleClosePopupEdit} variant="text" fontSize="large"><CloseIcon /></Button>
                </DialogActions>
                <DialogContent>
                    {!formLoaded && (
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
                                borderRadius: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Skeleton
                                variant="rectangular"
                                width="100%"
                                height="100%"
                                animation="wave"
                            />
                        </div>
                    )}
                    <iframe
                        src={ReleasePlanEditUrl}
                        width="100%"
                        height="100%"
                        title="External Content"
                        frameBorder={0}
                        onLoad={handleEditIframeLoad}
                        style={{ display: openForm ? 'block' : 'none' }}
                    />
                </DialogContent>
            </Dialog>
             {/* Comments Dialog */}
             <Dialog 
                sx={{ width: "1000px", margin: "auto" }}
                maxWidth={false}
                open={commentDialogOpen}
                onClose={() => setCommentDialogOpen(false)}
            >
                <DialogTitle sx={{ margin: "auto" }}>{TaskName}</DialogTitle>
                <DialogContent sx={{ padding: 0, height: "100%" }}>
                    {/* Sticky Text Field */}
                    <Box
                        sx={{
                            position: "sticky",
                            top: 0,
                            backgroundColor: "white",
                            padding: 2,
                            zIndex: 1,
                        }}
                    >
                        <TextField
                            fullWidth
                            multiline
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            placeholder="Write your comment here..."
                            rows={4}
                            sx={{
                                "& .MuiInputBase-root": {
                                    height: "100%",
                                    alignItems: "flex-start",
                                },
                            }}
                        />
                        <Button
                            onClick={handleAddComment}
                            color="primary"
                            variant="contained"
                            disabled={!newComment.trim()}
                            fullWidth
                            sx={{ marginTop: 2 }}
                        >
                            Add Comment
                        </Button>
                    </Box>

                    {/* Scrollable Comments List */}
                    <Box
    sx={{
        height: "calc(100% - 180px)", // Adjust height to account for sticky text field and button
        overflowY: "auto", // Enable vertical scroll
        padding: 2,
    }}
>
    {comments.length === 0 ? (
        <Typography style={{ textAlign: "center" }}>
            No comments yet. Be the first to add one!
        </Typography>
    ) : (
        comments.map((comment, idx) => (
            <Box
                key={idx}
                display="flex"
                alignItems="flex-start" // Align items flex-start for wrapping
                mb={2}
                sx={{ wordWrap: "break-word", wordBreak: "break-word", width: "100%" }} // Ensure wrapping
            >
                <PersonIcon style={{ marginRight: "8px", alignSelf: "flex-start" }} />
                <Box sx={{ width: "100%" }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {typeof comment.name === "string" ? comment.name : "Unknown User"}{" "}
                        <span style={{ fontSize: "0.8rem" }}>
                            ({typeof comment.timestamp === "string" ? comment.timestamp : "Unknown Time"})
                        </span>
                    </Typography>
                    <Typography variant="body2">
                        {typeof comment.text === "string" ? comment.text : "Invalid Comment"}
                    </Typography>
                </Box>
            </Box>
        ))
    )}
</Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setCommentDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </TableCell>
    );
}

