import React, { useState } from "react";
import {Box, Button, Collapse, TextField, Tooltip, ButtonGroup, Dialog, DialogContent, DialogActions, Skeleton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import RemoveIcon from "@mui/icons-material/Remove";
import FilterAltRoundedIcon from "@mui/icons-material/FilterAltRounded";
import SortRoundedIcon from "@mui/icons-material/SortRounded";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import styles from "../newTicketsTabCore/table/TicketsTable.module.css";
import { ReportedIssueFilter } from "./ReportedIssueFilterFolder/ReportedIssueFilter";
import { ReportedIssueTopChip } from './ReportedIssueFilterFolder/ReportedIssueTopChip'
import { ReportedIssueSortMenu } from "./ReportedIssueFilterFolder/ReportedIssueSortMenu";

const ReportedIssueTopBar = ({ actions, setActions }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const iframeUrl = "https://creatorapp.zoho.com/zohointranet/zvp-team/form-embed/Internal_Issue_Tracking?frameorigin=https://catalyst.zoho.com&zc_ifr_load=true&zc_Export=true";

  function handleCollapseKeydown(event) {
    if (event.code === "Escape") {
      setShowSearch(false);
    }
  }

  function handleSearchChange(event) {
    if(event.key === "Enter" && event?.target?.value?.trim() !== "") {
        setActions({
            ...actions,
            search_text: [undefined, null, ""].includes(event?.target?.value) ? undefined : event?.target?.value?.toLowerCase()
        });
    }
  }

  const HandleClosePopup = () => {
		setOpenPopup(false);
		setIframeLoaded(false);
	};

  const handleIframeLoad = () => {
		setIframeLoaded(true);
	};

  const handleMenuOpen = (event) => {
		setOpenPopup(true);
	};

  return (
    <Box className={styles.topbar} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
            <Button
            sx={{ borderRadius: "55%", height: "33px", width: "33px", marginRight: "10px" }}
            variant="contained"
            size="small"
            onClick={handleMenuOpen}
            >
            <AddIcon />
            </Button>
            {/* Search Button */}
        </Box>
      <ReportedIssueTopChip actions={actions} setActions={setActions}/>
      {/* Right Side: Add Icon and Actions */}
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Collapse
          in={showSearch}
          onKeyDown={handleCollapseKeydown}
          timeout="auto"
          orientation="horizontal"
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <TextField
            className={styles.search_box}
            autoFocus
            spellCheck="false"
            size="small"
            placeholder="Serch your comment here..."
            onKeyDown={handleSearchChange}
            style={{
              transition: "width 0.3s ease",
              width: showSearch ? "300px" : "0"
            }}
          />
        </Collapse>
        <ButtonGroup variant="contained" size="small">
          <Search showSearch={showSearch} setShowSearch={setShowSearch} />
          <Toggle actions={actions} setActions={setActions} />
          <Filter actions={actions} setActions={setActions} />
          <Sort actions={actions} setActions={setActions} />
          <DateSort actions={actions} setActions={setActions} />
        </ButtonGroup>
        <ReportedIssueFilter actions={actions} setActions={setActions} />
        <ReportedIssueSortMenu actions={actions} setActions={setActions} />
      </Box>
      <Dialog open={openPopup} onClose={HandleClosePopup} fullScreen>
				<DialogActions>
					<Button onClick={HandleClosePopup} variant="text" fontSize="large"><CloseIcon /></Button>
				</DialogActions>
				<DialogContent>
					{!iframeLoaded && (
						<div
							style={{
								position: 'relative',
								width: '100%',
								height: '100%',
								backgroundImage: 'linear-gradient(to right, #f0f0f0, #e0e0e0)',
								borderRadius: 0,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Skeleton
								variant="rectangular"
								width="100%"
								height="100%"
								animation="wave"
							/>
						</div>
					)}
					<iframe
						src={iframeUrl}
						width="100%"
						height="100%"
						title="External Content"
						onLoad={handleIframeLoad}
						frameBorder={0}
						style={{ display: iframeLoaded ? 'block' : 'none' }}
					/>
				</DialogContent>
			</Dialog>
    </Box>
  );
};

function Search({ showSearch, setShowSearch }) {
  function handleSearchButtonClick() {
    setShowSearch((state) => !state);
  }

  return (
    <Tooltip title="Search">
      <Button
        onClick={handleSearchButtonClick}
        className={showSearch ? styles.button_no_border : styles.button_border}
        style={{ minWidth: "auto" }}
      >
        <SearchRoundedIcon fontSize="medium" />
      </Button>
    </Tooltip>
  );
}

function Filter({ actions, setActions }) {
  function handleFilterClick(event) {
    setActions({
      ...actions,
      meta: {
        ...actions.meta,
        filter_anchor: actions?.meta?.filter_anchor === null ? event.currentTarget : null,
      },
    });
  }

  return (
    <Tooltip title="Filter">
      <Button onClick={handleFilterClick}>
        <FilterAltRoundedIcon fontSize="medium" />
      </Button>
    </Tooltip>
  );
}

function Sort({ actions, setActions }) {
  function handleSortClick(event) {
    setActions({
      ...actions,
      meta: {
        ...actions.meta,
        sort_anchor: actions?.meta?.sort_anchor === null ? event.currentTarget : null,
      },
    });
  }

  return (
    <Tooltip title="Sort">
      <Button onClick={handleSortClick}>
        <SortRoundedIcon fontSize="medium" />
      </Button>
    </Tooltip>
  );
}

function DateSort({actions, setActions}) {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [dateOpen, setDateOpen] = useState(false);

    const handleSubmit = () => {
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
        setActions({
            ...actions,
            startDate:formattedStartDate,
            endDate:formattedEndDate
        });
        setDateOpen(false);
      };
    
      const handleReset = () => {
        setDateOpen(false);
        setStartDate(null);
        setEndDate(null);
        setActions({
            ...actions,
            startDate:null,
            endDate:null
        });
      };

    return (
        <>
            <Tooltip title="Date Sort">
                <Button onClick={() => setDateOpen(s => !s)}><DateRangeOutlinedIcon fontSize='medium' /></Button>
            </Tooltip>
            {dateOpen && (
                <div style={{ position: 'absolute', top: '15%',right:"0.9%", zIndex: 1000, background: 'white', border: '1px solid #ccc', padding: '10px', maxHeight: "600px",
                  }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display="flex" flexDirection="row">
                            <Typography variant="caption">Start Date</Typography>
                                <DateCalendar value={startDate ? dayjs(startDate) : null} onChange={(newStartDate) => setStartDate(newStartDate)} />
                            <Typography variant="caption">End Date</Typography>
                            <DateCalendar  value={endDate ? dayjs(endDate) : null} onChange={(newEndDate) => setEndDate(newEndDate)} />
                        </Box>
                        <Box sx={{padding:"0px 250px !important",gap:"2px ! important"}}>
                            <Button sx = {{marginRight:"10px"}} onClick={() => setDateOpen(false)}>Cancel</Button>
                            <Button sx = {{marginRight:"10px"}} onClick={handleReset}>Reset</Button>
                            <Button onClick={handleSubmit} autoFocus> Submit</Button>
                        </Box>
                    </LocalizationProvider>
                </div>
            )}
          </>
    );
}

function Toggle({ actions, setActions }) {
  function handleToggleClick() {
    setActions({
      ...actions,
      show_closed: nextState(actions.show_closed),
    });
  }

  function nextState(cur) {
    switch (cur) {
      case true:
        return false;
      case false:
        return null;
      case null:
        return undefined;
      case undefined:
        return true;
    }
  }

  return (
    <Button onClick={handleToggleClick}>
      <Tooltip
        title={
          actions.show_closed === true
            ? "Showing Completed Issues"
            : actions.show_closed === false
            ? "Showing Moved To Release Planner Issues"
            : actions.show_closed === null
            ? "Showing Moved to Milestone Issues"
            : "Showing Issues"
        }
      >
        {actions.show_closed === true ? (
          <DoneRoundedIcon fontSize="medium" />
        ) : actions.show_closed === false ? (
          <MoreHorizRoundedIcon fontSize="medium" />
        ) : actions.show_closed === null ? (
          <RemoveIcon fontSize="medium" />
        ) : (
          <LocationSearchingIcon fontSize="medium" />
        )}
      </Tooltip>
    </Button>
  );
}

export default ReportedIssueTopBar;
