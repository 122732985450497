/* eslint-disable react/prop-types */
import React, {  useState, useEffect, useContext, useRef } from 'react';
import { ReortedIssueTable } from './ReportIssueTable'
import ReportedIssueTopBar from './ReportedIssueTopBar'
import { Box, useTheme } from '@mui/material';
import { UserContext } from '../../../../contexts/UserContext';
import { ORDER_BY } from './ReportedIssueFilterFolder/ReportedIssueSortMenu';
import { executeQuery } from '../../../../util/QueryDB';
import { reported_issue_component_get_query_1 } from '../../../../catalystDB/queryGenerator';

const ROWS_PER_FETCH = 100;

export function ReportedIssue() {

    const {loginUserEmail, teamDetails, loginUserDept, serverConfiguration, configuration, allServers, GlobalServer} = useContext(UserContext);
    const theme = useTheme();
    var userServers = (GlobalServer === "unknown" || GlobalServer === undefined) ?  teamDetails.current[loginUserEmail.current]?.Servers?.split(",") : GlobalServer;
    const [actions, setActions] = useState({
        search_text: undefined ,
        show_closed: undefined,
        filters: {
            servers: (GlobalServer === "unknown" || GlobalServer === undefined) ? userServers.filter((serv) => serv !== "Test" && allServers.includes(serv)) : [GlobalServer],
            module: [],
            type: []
        },
        sort: undefined,
        // metadata
        meta: {
            filter_anchor: null,
            filter_server_anchor: null,
            filter_module_anchor: null,
            filter_type_anchor: null,
            sort_anchor: null
        },
        startDate:null,
        endDate:null
    });
    const [currentFetchedRowsCount, setCurrentFetchedRowsCount] = useState(0); // set to -1 if all rows are fetched OR -2 if further fetching caused some error
    const [data, setData] = useState(undefined); // undefined -> fetch pending, null -> fetch failed
    var locked = useRef(false); // lazy load lock
    var ignore_list =  configuration.current[`tickets.ZVP.tickets_ignore_list`];
    if(ignore_list.includes(loginUserEmail.current)) {
        ignore_list = [];
    }
    
    // initially fetching first 100 rows
    useEffect(() => {
        
        locked.current = false;
        setData(undefined);
        setCurrentFetchedRowsCount(0);
        const query = getQuery(actions, loginUserDept, serverConfiguration, 0+1, ignore_list,loginUserEmail);
        executeQuery(query).then(response => {
            if (response.length === 0) {
                setCurrentFetchedRowsCount(-1);
                setData([]);
            } else {
                setData(response.map(ticket => ticket.Internal_Issue_Tracking));
                if (response.length < ROWS_PER_FETCH) {
                    setCurrentFetchedRowsCount(-1);
                } else {
                    setCurrentFetchedRowsCount(currentFetchedRowsCount + response.length);
                }
            }
        }).catch(error => {
            console.log(error);
            setData(null);
        });

    }, [actions.search_text, actions.show_closed, actions.filters, actions.sort]);

    // when scrolled to the bottom, 
    // fetching next 100 rows and extending the old data array if value is present,
    // if no value, currentFetchedRowsCount -> -1
    // if fetching further caused some error currentFetchedRowsCount -> -2
    async function getNextRows() {
        return new Promise((resolve, reject) => {
            if(currentFetchedRowsCount === -1 || currentFetchedRowsCount === -2 || data === undefined || data === null) {
                return resolve(false);
            }
            const query = getQuery(actions, loginUserDept, serverConfiguration, currentFetchedRowsCount+1, ignore_list,loginUserEmail);
            executeQuery(query).then(response => {
                if(response.length === 0) {
                    setCurrentFetchedRowsCount(-1);
                    return resolve(false);
                } else {
                    setData([...data, ...response.map(ticket => ticket.Internal_Issue_Tracking)]);
                    if(response.length < ROWS_PER_FETCH) {
                        setCurrentFetchedRowsCount(-1);
                        return resolve(false);
                    } else {
                        setCurrentFetchedRowsCount(currentFetchedRowsCount + response.length);
                        return resolve(true);
                    }
                }
            }).catch(error => {
                setCurrentFetchedRowsCount(-2);
                return resolve(false);
            });
        });
    }

    return(
        <Box className={`page-content-2`} sx={{color: theme.palette.background.textSecondary}}>
            <ReportedIssueTopBar actions={actions} setActions={setActions}/>
            <ReortedIssueTable data={data} fetchedRowsCount={currentFetchedRowsCount} getNextRows={getNextRows} locked={locked} actions={actions} />
        </Box>
    );
}

function getQuery(actions, loginUserDept, serverConfiguration, offset, ignore_list,loginUserEmail) {
    // console.log(ROWS_PER_FETCH)
    return reported_issue_component_get_query_1(actions, loginUserDept, serverConfiguration, offset, ignore_list, ORDER_BY, ROWS_PER_FETCH,loginUserEmail);
}