import React, { useState, useContext } from "react";

import { 
    Menu,
    MenuList,
    MenuItem,
    Divider
} from "@mui/material";
import { UserContext } from "../../../../../contexts/UserContext";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { update_status } from "../../newTicketsTabCore/table/postClaimActions";
import { delete_issue } from "../../newTicketsTabCore/table/postClaimActions";

import styles from '../../newTicketsTabCore/table/TicketsTable.module.css'

export const TICKET_STATUS = Object.freeze({
    TICKET_CREATED: "Ticket Created",
    OPEN: "Open",
    ON_HOLD: "On Hold",
    COMPLETED: "Completed",
    IMMEDIATE_FIX_IN_PROGRESS: "Immediate Fix In Progress",
    PERMANENT_FIX_IN_PROGRESS: "Permanent Fix In Progress",
    RESOLUTION_WAITING_FOR: "Resolution waiting for Approval",
    REJECTED: "Ticket Rejected"
});

export function ReportedIssueMoreMenu({data, anchor, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setLocalStatus, localDeleted, setLocalDeleted}) {

    const {loginUserEmail,teamDetails, loginUserZuid, zoneAdmin} = useContext(UserContext);
    const loginUserRole = teamDetails.current[loginUserEmail.current].Role;

    const MORE_ACTIONS = Object.freeze({
        MOVE_TO_RELEASE: {
            name: "To Release Plan",
            onClick: () => update_status(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, "Release"),
            icon: <NewReleasesIcon fontSize="medium" />
        }
    });
    
    function handleMoreMenuClose() {
        setAnchor(null);
        setMoreIconVisible(false);
    }

    const actions =  Object.values(MORE_ACTIONS);

    const filteredActions = data.Status === "Open" || data.Status === "Ongoing" ? actions : null;

    return (
        <>
            <Menu anchorEl={anchor}
                open={anchor !== null}
                onClose={handleMoreMenuClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuList className={styles.more_menu_main}>
                    {
                        filteredActions !== null ? (
                            filteredActions.map((action, index) => {
                                return (
                                    <>
                                        <MenuItem className={styles.more_menu_item} key={index} onClick={action.onClick}>
                                            {action.name}
                                            {action.icon}
                                        </MenuItem>
                                        <Divider light style={{ margin: 0 }} />
                                        <MenuItem onClick={() => delete_issue(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setLocalDeleted)} className={styles.more_menu_item} key={actions.length}> 
                                            Delete
                                            <DeleteOutlineIcon fontSize="medium" />
                                        </MenuItem>
                                        <Divider light style={{ margin: 0 }} />
                                    </>
                                );
                            })
                        )
                        :(
                            <>
                                <MenuItem onClick={() => delete_issue(data, setAnchor, setMoreIconVisible, setEventFeedbackQueue, setLocalDeleted)}  className={styles.more_menu_item} key={actions.length}> 
                                    Delete
                                    <DeleteOutlineIcon fontSize="medium" />
                                </MenuItem>
                                <Divider light style={{ margin: 0 }} />
                            </>
                        )
                    }
                </MenuList>
            </Menu>
        </>
    );
}