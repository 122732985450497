/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, LinearProgress, CircularProgress, Snackbar, IconButton} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import styles from '../newTicketsTabCore/table/TicketsTable.module.css'
import { ReportIssueRow } from './ReportIssueRow';
import { find_row_span } from '../../../../util/GroupingFunctions';


export function ReortedIssueTable({data, fetchedRowsCount, getNextRows, locked, actions}) {
    const theme = useTheme();
    var lastScrollTop = useRef(0);
    const loadingDiv = useRef();
    const scrollContainer = useRef();
    const [eventFeedbackQueue, setEventFeedbackQueue] = useState([]);
    
    useEffect(() => {
        setEventFeedbackQueue([]);
    }, []);

    async function handleScroll(event) {
		const myDiv = scrollContainer.current;
		var needMore = false;

		if (myDiv.scrollTop > lastScrollTop.current && (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight - 50)) {
			needMore = true;
		}

		lastScrollTop.current = myDiv.scrollTop;
		if (needMore && !locked.current) {
			locked.current = true;
            if(await getNextRows()) { // if more rows are yet to fetch
                locked.current = false; // free the lock
            }
		}
	}

    if(data === undefined) {

        return (
            <Box className={styles.ticket_content_full}>
                <Box className={styles.translate_y}>
                    <CircularProgress size={"2.4rem"} />
                    fetching tickets...
                </Box>
            </Box>
        );

    } else if(data === null) {
        return (
            <Box className={styles.ticket_content_full}>
                <Box className={styles.translate_y}>
                    Uh Oh, Something went bad :(
                </Box>
            </Box>
        );
    } else if(data.length === 0) {
        return (
            <Box className={styles.ticket_content_full}>
                <Box className={styles.translate_y}>
                    {
                        actions.show_closed === true ?
                            "No Closed Tickets Found!"
                            :
                        actions.show_closed === false ?
                            "No Ongoing Tickets Found!"
                            :
                        actions.show_closed === null ?
                            "No Rejected Tickets Found!"
                            :
                            "No Tickets Found!"
                    }
                </Box>
            </Box>
        );
    } else {

        const row_span_details = getRowSpanDetails(data); // rowspan info for Product_Type and Type

        return (
            <Box className={styles.tickets_table_section} >
                <Paper className={styles.ticket_table_surface}>
                    <TableContainer className={styles.ticket_table_container} onScroll={handleScroll} ref={scrollContainer}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead className={`${styles.ticket_table_head} ${theme.palette.mode}`}>
                                <TableRow>
                                    <TableCell className={styles.side_grouper_head}> </TableCell> {/* left side grouper -> Product Type */}
                                    <TableCell className={styles.serial_number}> S.No </TableCell> {/* serial number */}
                                    <TableCell align='center'> Title </TableCell>
                                    <TableCell align='center'> Description </TableCell>
                                    <TableCell> Reported By </TableCell>
                                    <TableCell> Created On </TableCell>
                                    <TableCell> DRI </TableCell>
                                    <TableCell align='center'> Priority </TableCell>
                                    <TableCell align='center'> Status </TableCell>
                                    <TableCell className={styles.more_section} align='left'> </TableCell> {/* more section */}
                                    <TableCell className={styles.side_grouper_head}> </TableCell> {/* right side grouper -> Issue | Query */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* normal rows */}
                                {
                                    data.map((row, index) => {
                                        return <ReportIssueRow data={row} key={`new-tickets-${index}`} index={index} setEventFeedbackQueue={setEventFeedbackQueue} rowSpanDetails={row_span_details[index]} />
                                    })
                                }
                                {/* loading row */}
                                {
                                    fetchedRowsCount >= 0 ?
                                        <TableRow ref={loadingDiv} className={`${styles.ticket_table_row} ${theme.palette.mode} ${styles.last_loading_row}`}>
                                            <TableCell colSpan={12}>
                                                <LinearProgress />
                                            </TableCell>
                                        </TableRow>
                                        :
                                        null
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {/* feeback snackbar */}
                {
                    eventFeedbackQueue.map((feedback, index) => {

                        const handleClose = (event, reason) => {
                            if (reason === 'clickaway') {
                                return;
                            }
                            const new_array = eventFeedbackQueue.filter(fdbk => fdbk.message !== feedback.message);
                            setEventFeedbackQueue(new_array);
                        };

                        const action = (
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                        );

                        const content = (
                            <Box className={`${styles.flex_row_center}`}>
                                {
                                    feedback.loading ?
                                        <CircularProgress size={"1.5rem"} sx={{ color: "green" }} />
                                        :
                                        feedback.success ?
                                            <DoneRoundedIcon size={"1.5rem"} sx={{ color: "green" }} />
                                            :
                                            <ErrorOutlineRoundedIcon size={"1.5rem"} sx={{ color: "green" }} />
                                }
                                {feedback.message}
                            </Box>
                        );

                        return (
                            <Snackbar
                                key={feedback.message || `feedback-${index}`} // Add a key here
                                open={true}
                                onClose={handleClose}
                                autoHideDuration={feedback.auto_hide ? 3500 : null}
                                message={content}
                                action={action}
                                className={`${styles.feedback_snackbar} ${styles[theme.palette.mode]}`}
                            />
                        );

                    })
                }

            </Box>
        );
    }
}

function getRowSpanDetails(data) {
    const table = data.map(row => [row.Server, row.Type]);

    const row_span_details = find_row_span(table, [1]);

    return row_span_details;
}