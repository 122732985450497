import React, { useState, useContext, useRef, useEffect } from 'react';
import { 
    Box,
    TableCell,
    TableRow,
    useTheme,
    Fade,
    Tooltip,
    Select,
    MenuItem
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

import { update_status } from '../newTicketsTabCore/table/postClaimActions';
import styles from '../newTicketsTabCore/table/TicketsTable.module.css'
import { ReportedIssueMoreMenu } from './ReportedIssueFilterFolder/ReportedIssueMoreMenu';
import { GetNameAndEmpID } from '../../../../util/Loading';
import * as d3 from "d3";


export function ReportIssueRow({data, index, setEventFeedbackQueue, rowSpanDetails}) {
    const theme = useTheme();
    const row = useRef();
    const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
    const [moreIconVisible, setMoreIconVisible] = useState(false);
    const [moreViewOpen, setMoreViewOpen] = useState(false);
    const statusOptions = ["Open", "Ongoing", "Release", "To Milestone", "Completed"];

    const [localStatus, setLocalStatus] = useState(null);
    const [localDeleted, setLocalDeleted] = useState(false);

    useEffect(() => {
        setLocalStatus(null);
    }, []);



    function handleMoreIconClick(event) {
        setMoreMenuAnchor(event.currentTarget);
    }

    function handleMoreViewClick() {
        setMoreViewOpen(true);
    }

    const handleStatusChange = (event, rowData) => {
        const newStatus = event.target.value;
        update_status(rowData, setMoreMenuAnchor, setMoreIconVisible, setEventFeedbackQueue, newStatus)
        setStatusValue(newStatus);
        // console.log(`Status changed to ${newStatus} for row`, rowData);
    };

    const d = sanitize_and_format(data, localStatus, theme);
    const [statusValue, setStatusValue] = useState(d.Status);

    return (
        <TableRow className={`${styles.ticket_table_row} ${theme.palette.mode} ${localDeleted ? styles.temp_deleted_row : ``}`} hover
            sx={{
                '&:hover': {
                    backgroundColor: theme.palette.background.tableRowHover + " !important"
                }
            }}
            ref={row}
            onMouseEnter={() => setMoreIconVisible(true)}
            onMouseLeave={() => setMoreIconVisible(false)}
        >
            {
                rowSpanDetails[0] === 0 ? 
                    null
                    :
                    <Tooltip title={d.Server} followCursor>
                        <TableCell className={styles.side_grouper_main} rowSpan={rowSpanDetails[0]} sx={{backgroundColor: interpolateColor(hashCode(d.Server))}}> {/* left side grouper -> Product Type */}
                            <Box className={styles.side_grouper_main_left}>
                                {d.Server}
                            </Box>
                        </TableCell>
                    </Tooltip>
            }
            <TableCell className={styles.serial_number} align='right' onClick={handleMoreViewClick} sx={{cursor: "pointer"}}> 
                {index+1} 
            </TableCell> {/* serial number */}
            <TableCell className={styles.bold} sx={{textAlign:'center'}}> 
                {d.Title}
            </TableCell>
            <TableCell sx={{textAlign:'center'}} className={styles.time_fixed_width} onClick={handleMoreViewClick}> 
                {d.Description} 
            </TableCell>
            <TableCell className={styles.dri_cell} onClick={handleMoreViewClick} sx={{cursor: "pointer"}}> 
                {d.ReportedUser} 
            </TableCell>
            <TableCell className={styles.description_max_width}> 
                {/* <Tooltip title={d.description} > */}
                    {d.CREATEDTIME}
                {/* </Tooltip> */}
            </TableCell>
            <TableCell className={styles.dri_cell} onClick={handleMoreViewClick} sx={{cursor: "pointer"}}> 
                {d.DRI} 
            </TableCell>
            <TableCell align='center' onClick={handleMoreViewClick} sx={{cursor: "pointer"}}> 
                {d.Priority_Level} 
            </TableCell>
            {/* <TableCell align='center'> 
                {d.Status} 
            </TableCell> */}
            <TableCell align="center" sx={{ cursor: "pointer" }}>
                    <Select
                        value={statusValue}
                        onChange={(e) => handleStatusChange(e, d)} // Add this function to update status
                        displayEmpty
                        sx={{ minWidth: "120px" }}
                    >
                        {statusOptions.map((status) => (
                            <MenuItem key={status} value={status}>
                                {status}
                            </MenuItem>
                        ))}
                    </Select>
            </TableCell>
            <TableCell className={styles.more_section} align='left'> 
                {
                        <>
                            <Fade in={moreIconVisible} timeout={100}>
                                <MoreVertRoundedIcon onClick={handleMoreIconClick} className={styles.mouse_pointer}/>
                            </Fade>
                            <ReportedIssueMoreMenu data={data} anchor={moreMenuAnchor} setAnchor={setMoreMenuAnchor} setMoreIconVisible={setMoreIconVisible}
                                setEventFeedbackQueue={setEventFeedbackQueue} localDeleted={localDeleted} setLocalDeleted={setLocalDeleted}
                            />
                        </>
                }
            </TableCell> {/* more section */}
            {
                rowSpanDetails[1] === 0 ? 
                    null
                    :
                    <Tooltip title={d.Type} followCursor>
                        <TableCell className={styles.side_grouper_main} rowSpan={rowSpanDetails[1]} sx={{backgroundColor: interpolateColor(hashCode(d.Type))}}> {/* right side grouper -> Issue | Query */}
                            <Box className={styles.side_grouper_main_right}>
                                {d.Type}
                            </Box>
                        </TableCell>
                    </Tooltip>
            } 
        </TableRow>
    );
}

// util functions

function sanitize_and_format(data, localStatus, theme) {
    var d = Object.assign({}, data);
    d.DeskTicketNumber = isUnclean(d.DeskTicketNumber) ? "-" : `#${d.DeskTicketNumber}`;
    d.CREATEDTIME = isUnclean(d.CREATEDTIME) ? "-" : formatDateTime(d.CREATEDTIME);
    d.Description = isUnclean(d.Description) ? "-" : d.Description;
    d.ReportedUser = isUnclean(d.ReportedUser) ? "-" : <GetNameAndEmpID emails={d.ReportedUser} />;
    d.Title =  isUnclean(d.Title) ?
        (
               "-"
        )
        :
        (
            <Box className={`${styles.flex_center_align} ${styles.gap_small}`}>
                {d.Title}
                <Box className={`${styles.light_chip} ${styles[theme.palette.mode]}`} sx={{ color: "#d13819" }}> {d.Type} </Box>       
            </Box>
        );
    // console.log(d.IssueReportedBy);

    d.DRI = (
            <GetNameAndEmpID emails={d.DRI} />
    );
    const color = d.Priority_Level === "Normal" ? "#188a04" : d.Priority_Level === "High" ? "#d1870f" : "#d10f0f";
    const colorBackground = d.Priority_Level === "Normal" ? "#188a0422" : d.Priority_Level === "High" ? "#d1870f22" : "#d10f0f22";
    d.Priority_Level = isUnclean(d.Priority_Level) ? "-" : 
        <Box sx={{borderRadius: "50px", border: `0.2px solid ${color}`, padding: "5px 5px", fontSize: "0.65rem", backgroundColor: colorBackground}}>
            <box style={{color: color, fontWeight: "bold", minWidth: 0}}>{d.Priority_Level}</box>
        </Box>
    d.Status = localStatus !== null ?
        localStatus
        :
        (
            isUnclean(d.Status) ?
                "-"
                :
                d.Status
        );
    d.Server = isUnclean(d.Server) ? "-" : d?.Server?.replaceAll("_", "-");
    return d;
}

function isUnclean(item) {
    return [undefined, null, NaN, "", " ", "-"].includes(item);
}

function formatDateTime(dateTimeString) {
    // Parse the date (adjusting for the time zone offset)
    const date = new Date(dateTimeString.slice(0, -4));
    const currentDate = new Date();
    const diffInHours = (currentDate - date) / (1000 * 60 * 60);
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    let dateLabel;
    
    if (isToday(date)) {
        dateLabel = "Today";
    } else if (isYesterday(date)) {
        dateLabel = "Yesterday";
    } else {
        dateLabel = formattedDate;
    }

    const delayedText = diffInHours > 24 ? "#delayed" : "";

    return (
        <Box className={`${styles.flex_column} ${styles.flex_left_align}`}>
            <Box sx={{ color: "#d13819", fontWeight: "bold" }}>
                {delayedText}
            </Box>
            <Box>
                {dateLabel}
            </Box>
            <Box>
                {formattedTime}
            </Box>
        </Box>
    );
}


function isToday(someDate) {
    const today = new Date();
    return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
    );
}

function isYesterday(someDate) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
        someDate.getDate() === yesterday.getDate() &&
        someDate.getMonth() === yesterday.getMonth() &&
        someDate.getFullYear() === yesterday.getFullYear()
    );
}

function hash(string) {
    var hash = 0;
    if (string.length == 0) return hash+"";
    for (var x = 0; x < string.length; x++) {
        var ch = string.charCodeAt(x);
        hash = ((hash << 5) - hash) + ch;
        hash = hash & hash;
    }
    return hash+""+string;
}

function hashCode(str) {
    str = hash(str);
    const stringLength = str.length;
    const splitIndex = Math.floor(stringLength / 2); // Find the middle index

    // Use the character code of the character at the split index to map it to a number between 0 and 9
    const charCodeAtSplitIndex = str.charCodeAt(splitIndex);
    const mappedNumber = (charCodeAtSplitIndex + 6) % 10;

    return mappedNumber / 10;
}

function interpolateColor(t) {
    const originalColor = d3.interpolateRainbow(t);
    const rgbColor = d3.color(originalColor);

    const darkerColor = `rgb(${rgbColor.r * 0.5}, ${rgbColor.g * 0.5}, ${rgbColor.b * 0.5})`;

    return darkerColor;
}

function getHistory(hist) {
    try {
        const hist_items = hist.split("-----------------------");
        var arr = hist_items.map(item => {
            if(item === "") {
                return;
            }
            try {
                return [
                    item.split("###")[0]?.split("=>>")[0]?.trim(),
                    item.split("###")[0]?.split("=>>")[1]?.trim(),
                    item.split("###")[0]?.split("=>>")[2]?.trim(),
                    item.split("###")[1]?.trim().replaceAll("\\n", "").replaceAll("\n", "")
                ];
            } catch(e) {
                return;
            }
        });
        arr = arr.filter(item => item !== undefined);
        return (
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                    },
                }}
            >
                {
                    arr.map(item => {
                        return (
                            <TimelineItem>
                                <TimelineOppositeContent color="textSecondary">
                                    {formatDateTime_(item[3])}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Box sx={{minHeight: "100px !important", gap: "10px", display: "flex", flexDirection: "column", wordWrap: "break-word" }}>
                                        <GetNameAndEmpID emails={item[2]} />
                                        {item[1]}
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })
                }
            </Timeline>
        );
        return arr;
    } catch(e) {
        return "-";
    }
}

function formatDateTime_(dateTimeString) {
    const date = new Date(dateTimeString.slice(0, -4));
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', dateOptions);
    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return (
        <Box className={`${styles.flex_column} ${styles.flex_right_align}`} sx={{minWidth: "70px !important"}}>
            <Box> {isToday(date) ? "Today" : isYesterday(date) ? "Yesterday" : formattedDate} </Box>
            <Box sx={{ color: "#d13819", fontWeight: "bold" }}> {formattedTime} </Box>
        </Box>
    );
}